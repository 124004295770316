// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import axios from "axios";
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import { Link, NavLink, useHistory } from "react-router-dom";
  //import NavBar from '../components/NavBar';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from 'react-i18next'
  import "../style.css";
  //import Sidebar from '../components/Sidebar';
  import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
  import BlurCircularIcon from '@mui/icons-material/BlurCircular';
  import SearchIcon from '@mui/icons-material/Search';
  import cookies from 'js-cookie'
  import FilterAltIcon from '@mui/icons-material/FilterAlt';
  import CloseIcon from '@mui/icons-material/Close';
  import Loading from "../images/loading2.svg";
  import {
    Button,
    CircularProgress,
    Popover,
    Typography,
  } from '@material-ui/core';
  import {
    createStyles,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

  } from '@material-ui/core';
  import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
  import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";

import { components } from "react-select";
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useTypedSelector } from 'helpers';
import { useSelector } from 'react-redux';
import { log } from 'console';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasWriteAccessToMasterConfigSubSystem } from "selectors";

const useStyles = makeStyles(() =>
createStyles({
  table: {minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
  addButton: { ...basicButtonStyles },
  row: { ...tableRowStyles },
})
);
const MerchantsTable: React.FC = () => {
  const intl = useIntl();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true); // Loading state
      //const history.push = useNavigate();
      const { t } = useTranslation()
      const history = useHistory();
      const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
       //req
       const [mainApiResponseData,setMainApiResponseData] =  useState<any[]>([]);
       const [page, setPage] = useState<any>(1);
       const [limit, setLimit] = useState<any>(10);
       const [pageCount, setPageCount] = useState<any>(0);
       const [dataCountNumber, setDataCountNumber] = useState(null); 
       const [status, setStatus] = useState(1); 
       const [deleteResponse, setDeleteResponse] = useState(null); 
       let loggedInUserPersonalData: any = '';
       let loggedInUserAccessTokenClientUid :any= '';
       
       const authData = localStorage.getItem("authData");
       
       if (authData !== null) {
         const parsedAuthData = JSON.parse(authData);
         loggedInUserPersonalData = parsedAuthData.userData;
         loggedInUserAccessTokenClientUid = parsedAuthData.userToken;
        
       }
       
       
      useEffect(()=>{

      const makeRequest =  () => {

let url=(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getAllMerchants?status=${status}&page=${page}&pageSize=${limit}`)
console.log(url);   
setIsLoading(true); // Set loading to true when data is being fetched
    
//setTimeout(() => {
 axios.get(url,
  
    { headers:{'access-token' : loggedInUserAccessTokenClientUid['access-token'],'uid' :loggedInUserAccessTokenClientUid.uid , 'client': loggedInUserAccessTokenClientUid.client }})
   .then((res)=> {


  setMainApiResponseData(res.data.data.result)
  setDataCountNumber(res.data.data.totalCount)    
  setPageCount(res.data.data.totalPages);
  setIsLoading(false); // Set loading to false when data is fetched
     
      })
      .catch((err)=>{
        
       
        
console.log("API ERROR");
toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #r1`, {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
  }) 
  setIsLoading(false); // Set loading to false when data is fetched
         
      })
    //}, 3000);
  } 
      makeRequest();
   
      
        },[page,limit,status,deleteResponse])
        function handlePrevious() {
          setPage((p) => {
            if (p === 1) return p;
            return p - 1;
          });
        }
      
        function handleNext() {
          setPage((p) => {
            if (p === pageCount) return p;
            return p + 1;
          });
        }
        function handleChangeLimit(lim) {
          setLimit(lim);
          setPage(1)
        }
        function changeStatus(s) {
            setStatus(s);
            setPage(1)
          }
        const hasWriteAccess = useTypedSelector(state =>
            hasWriteAccessToMasterConfigSubSystem(state, 'Merchants')
          );
        const moveToAddPage = (id) =>{
            history.push(`/CardsManagement/merchants/new`);
           } 
           const moveToEditPage = (id, event) =>{
            event.stopPropagation(); // Stop event propagation
            history.push(`/CardsManagement/merchants/${id}/edit?came_from=all`);
           } 
           
  const deleteMerchant = async (merchantName,merchantEnName,id,event) => {
   event.stopPropagation(); // Stop event propagation
    setIsLoading(true);
  
    try {
      let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/updateMerchant`;
      const response = await axios.post(url, {
        MERCHANT_STATUS: 3,
        MERCHANT_NAME: merchantName,
        MERCHANT_EN_NAME: merchantEnName,
        MERCHANT_ID: id,
      }, {
        headers: {
          'access-token': loggedInUserAccessTokenClientUid['access-token'],
          'uid': loggedInUserAccessTokenClientUid?.uid,
          'client': loggedInUserAccessTokenClientUid?.client,
        },
      });
      setDeleteResponse(response.data)
      toast.success('Merchant Deleted successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });
      
   
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
      
        toast.error(`Failed to Delete merchant [${error.response.data.message}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
       
        toast.error(`Failed to Delete merchant [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const tableRowClickNavigation= (id) =>{
    history.push(`/CardsManagement/merchants/${id}`);
   }
   const startItem = (page - 1) * limit + 1;
   const endItem = Math.min(page * limit, dataCountNumber || 0);
    return (
      <div className='dev-contain-all-page-content'>
          <h2 className='header-of-subsystem-page'>
            <BlurCircularIcon style={{margin:"10px  10px 13px 10px",fontSize:"28px"}}/>
            {intl.formatMessage({ id: 'Merchants' })}
          </h2>
          {hasWriteAccess && <button className="styleAddBtn" style={
            currentLanguageCode == "en-us"?
            {  right: "90px"}:
            {  left:"90px"}
          }
            onClick={()=>history.push(`/CardsManagement/merchants/new`)}
          >
            {intl.formatMessage({ id: 'AddMerchant' })}
          </button>}
          { loggedInUserPersonalData && loggedInUserAccessTokenClientUid ? <>
          <div className='table-controller-sec' >
       <div >    
         <span style={{margin:"10px",direction:"ltr"}}><bdi>
        <span className='show-span'style={{margin:"10px",direction:"ltr"}}> <bdi>
         {intl.formatMessage({ id: 'status' })}  </bdi></span>
       <select className='show-select'style={{direction:"ltr"}} onChange={(e)=>changeStatus(e.target.value)}>
  
  <option value="1">{ currentLanguageCode == "en-us"?"Active":"مفعل"}</option>
  <option value="2">{ currentLanguageCode == "en-us"?"Blocked":"محظور"}</option>
  <option value="3">{ currentLanguageCode == "en-us"?"Deleted":"محذوف"}</option>
 
  </select></bdi>
  </span></div>
       
  
        <div style={{direction:"ltr"}}> 
   
    {mainApiResponseData?.length>0 ?<>
     { currentLanguageCode == "en-us"? 
     <span style={{margin:"10px",direction:"ltr"}}>
        {page} 
            <span className='show-span'>
                <bdi style={{margin:"10px"}}>
                    {intl.formatMessage({ id: 'From' })}
                </bdi>
            </span>
        {pageCount}
     </span>
  
  :  <span style={{margin:"10px",direction:"ltr"}}>
         { pageCount}  
           <span className='show-span'>
                <bdi style={{margin:"10px"}}> 
                    {intl.formatMessage({ id: 'From' })} 
                </bdi>
           </span> 
         {page} 
     </span>}
     <button className='page-navigation-arrow'  disabled={currentLanguageCode == "en-us"?  page === 1 :page === pageCount } onClick={currentLanguageCode == "en-us"?  handlePrevious :handleNext}>
         <span style={{direction:"ltr"}}>   
             <KeyboardArrowLeftIcon style={{fontSize:"30px"}}/>
          </span>
     </button>
     <bdi> {startItem} - {endItem} {intl.formatMessage({ id: 'Of' })}  {dataCountNumber} </bdi>
     <button className='page-navigation-arrow' disabled={currentLanguageCode == "en-us"? page === pageCount:page === 1 } onClick={ currentLanguageCode == "en-us"?  handleNext:handlePrevious}>
        <span style={{direction:"ltr"}}>   
            <KeyboardArrowRightIcon style={{fontSize:"30px"}}/>
        </span>
     </button>
  </>: <></>}
    <span style={{margin:"10px"}}>
        <bdi>
          <span className='show-span'style={{margin:"10px"}}>  
                 {intl.formatMessage({ id: 'Show' })}  
          </span>
          <select className='show-select' onChange={(e)=>handleChangeLimit(e.target.value)}>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
          </select>
      </bdi>
  </span>
  </div>
  </div></>:<></>}
     { loggedInUserPersonalData && loggedInUserAccessTokenClientUid ?
        <div className="Table-Wrapper  " >
  
  <Table
className={classes.table}
size="small"
aria-label="zoning Routes Table"
>
<TableHead style={{border:"0"}}>
  <TableRow style={{border:"0"}}>
    
    <TableCell align="center">
    {intl.formatMessage({ id: 'EnglishName' })}
    </TableCell>
    <TableCell align="center">
    {intl.formatMessage({ id: 'ArabicName' })}
    </TableCell>
    <TableCell align="center">
    {intl.formatMessage({ id: 'POSCount' })}
    </TableCell>
    <TableCell align="center">
    <th> {intl.formatMessage({ id: 'status' })}</th>
    </TableCell>

    {hasWriteAccess && <><TableCell align="center">
    {intl.formatMessage({ id: 'actions' })}
    </TableCell></>}
  </TableRow>
</TableHead>
<TableBody>
    { 
    isLoading ? (
      <tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>
        <div style={{textAlign:"center",marginTop:"10px"}}>
<CircularProgress  style={{width:"50px"}} />
</div>
        </td></tr>
    ) : 
  
    mainApiResponseData?.length>0 ? 
    mainApiResponseData?.map((r,i) =>

          <TableRow key={r.merchant_id} onClick={()=>tableRowClickNavigation(r.merchant_id)}  className={classes.row}>
          <TextCell
    label={ r.merchant_en_name }
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
            <TextCell
    label={r.merchant_name }
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
          <TextCell
    label={r.pos_count }
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
              <TextCell
    label={r.merchant_status == 1 ?<CheckCircleIcon style={{color:"#81D135",margin:" 10px"}}/>:r.merchant_status == 2 ?<CheckCircleIcon style={{color:"black",margin:"10px"}}/>:<CheckCircleIcon style={{color:"red",margin:"10px"}}/>}
    style={{ fontFamily: 'Cairo' }}
  />
           {hasWriteAccess && <>     
               <TextCell
    label={   r.merchant_status == 3 ? <> <EditIcon className="editAndDeleteIcon"  onClick={(e)=>moveToEditPage(r.merchant_id,e)}/></>:
    <><EditIcon className="editAndDeleteIcon"  onClick={(e)=>moveToEditPage(r.merchant_id,e)}/>
    <><button  className="delBtn editAndDeleteIcon"  data-bs-toggle="modal"   onClick={(e) => e.stopPropagation()}  data-bs-target={"#exampleModal"+ r.merchant_id }><DeleteIcon/></button>
    <div className="modal fade" style={{zIndex:"9999969"}} id={"exampleModal"+ r.merchant_id } tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
         
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <h5>  {currentLanguageCode == "en-us" ? `Are You Sure You Want To Delete ${r.merchant_en_name}` : `هل أنت متأكد انك تريد حذف ${r.merchant_name}`}</h5>
            </div>
          <div className="modal-footer" style={{direction:currentLanguageCode == "en-us" ? "ltr":"rtl"}}>
          <button type="button" onClick={(e) => e.stopPropagation()} className="btn btn-outline-dark" data-bs-dismiss="modal">{intl.formatMessage({ id: 'Cancel' })} </button>
            <button type="button" 
            className="btn btn-danger"data-bs-dismiss="modal"
            
          onClick={(e)=>deleteMerchant(r.merchant_name,r.merchant_en_name,r.merchant_id,e)}>{intl.formatMessage({ id: 'Delete' })} </button>
      
          </div>
        </div>
      </div>
    </div></></>}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />     </>  }
  
  </TableRow>)
  :<tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</td></tr>
    } 
   </TableBody>
  </Table>
        </div> : <h2>
        <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
          </h2>}
  
        
      </div>    
    )
  }
  
  export default MerchantsTable